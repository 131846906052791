<template>
  <div style="height: inherit">
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
            </div>
            <div class="view-options d-flex" />
          </div>
        </div>
      </div>
    </section>

    <div>
      <div v-if="!loading">

        <b-tabs
          content-class="pt-1"
          fill
        >
          <b-tab
            :title="$t('Summary')"
            lazy
          >
            <b-row>
              <b-col>
                <b-card-actions
                  action-collapse
                >
                  <radar
                    :data="series"
                    title=""
                    :labels="labels"
                  />
                </b-card-actions>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-card>
                  <b-form-input
                    v-model="searchTerm"
                    :placeholder="$t('Filter Players')"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-for="(table, index) in summaryTables"
                :key="index"
                md="6"
              >
                <stats-table
                  :title="table.title"
                  :rows="table.data"
                  :columns="columns"
                  :page-length="5"
                  :search-term="searchTerm"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            :title="$t('Leader Board')"
            lazy
          >
            <b-row>
              <b-col>
                <b-card>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Filter Players"
                  />
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                md="12"
              >
                <stats-table
                  title="Leader Board"
                  :rows="finalSummary"
                  :columns="columns"
                  :page-length="25"
                  :search-term="searchTerm"
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

      </div>
      <div v-else>
        <loading />
      </div>
    </div>

    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar-summary
        :filters="filters"
        :trends="trends"
        :benchmark="benchmark"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTabs, BTab,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import StatsTable from '../../components/StatsTable.vue'
import configuration from './config'
import LeftFilterSidebarSummary from '../../components/LeftFilterSidebarSummary.vue'
import Radar from '../../components/Radar.vue'
import Loading from '../../components/Loading.vue'

export default {
  components: {
    // BOverlay,
    BCardActions,
    BCard,
    BRow,
    BCol,
    BFormInput,
    StatsTable,
    LeftFilterSidebarSummary,
    BTabs,
    BTab,
    Radar,
    Loading,
  },
  props: {
    groupId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      finalSummary: [],
      summaryTables: [],
      tour: [],
      filters: {
        greenspeed: -1,
        rain: -1,
        wind: -1,
        temperature: null, // new
        holesNumber: 18, // played not 0
        qualification: -1,
        competition: -1,
        shotByShot: 1,
        roundType: null,
        selectedPlayers: [],
        selectedGroup: null,
        startDate: '',
        endDate: '',
      },
      trends: {
        greenspeed: -1,
        rain: -1,
        wind: -1,
        temperature: null, // new
        holesNumber: 18, // played not 0
        qualification: 1,
        competition: -1,
        shotByShot: 1,
        roundType: null,
        selectedPlayers: [],
        selectedGroup: null,
        startDate: '',
        endDate: '',
      },
      loadingTab: true,
      currentTab: 0,
      c: configuration,
      labels: [],
      tables: [],
      series: [],
      searchTerm: '',
      plop: 0,
    }
  },
  computed: {
    columns() {
      return [
        {
          label: '',
          field: 'rank',
        },
        {
          label: this.$t('Name'),
          field: 'full_name',
        },
      ]
    },
    config() {
      return [
        {
          title: this.$t('Total'),
          key: 'total',
        },
        {
          title: this.$t('Putting'),
          key: 'onGreen',
        },
        {
          title: this.$t('Off The Tee'),
          key: 'offTee',
        },
        {
          title: this.$t('Long Shots & Lay-Ups'),
          key: 'longShots',
        },
        {
          title: this.$t('Greenside'),
          key: 'around',
        },
        {
          title: this.$t('To The Green'),
          key: 'approach',
        },
      ]
    },
    filterOptions() {
      return {
        greenspeed: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('Slow'),
            value: 0,
          },
          {
            text: this.$t('Med'),
            value: 1,
          },
          {
            text: this.$t('Fast'),
            value: 2,
          },
        ],
        rain: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('No Rain'),
            value: 0,
          },
          {
            text: this.$t('Rain'),
            value: 1,
          },
        ],
        wind: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('Low'),
            value: 0,
          },
          {
            text: this.$t('Med'),
            value: 1,
          },
          {
            text: this.$t('Strong'),
            value: 2,
          },
        ],
        temperature: [
          {
            text: this.$t('All'),
            value: null,
          },
          {
            text: this.$t('Normal'),
            value: 0,
          },
          {
            text: this.$t('Hot'),
            value: 1,
          },
          {
            text: this.$t('Cold'),
            value: -1,
          },
        ],
        holesNumber: [
          {
            text: this.$t('9 Holes'),
            value: 9,
          },
          {
            text: this.$t('18 Holes'),
            value: 18,
          },
        ],
        qualification: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('Normal'),
            value: 1,
          },
          {
            text: this.$t('Qualification'),
            value: 3,
          },
          {
            text: this.$t('Competition'),
            value: 2,
          },
        ],
        competition: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: '1',
            value: 1,
          },
          {
            text: '2',
            value: 2,
          },
          {
            text: '3',
            value: 3,
          },
          {
            text: '4',
            value: 4,
          },
        ],
        shotByShot: [
          {
            text: this.$t('Flash'),
            value: 0,
          },
          {
            text: this.$t('Full'),
            value: 1,
          },
        ],
        roundType: [
          {
            text: this.$t('All'),
            value: null,
          },
          {
            text: this.$t('Worst 5'),
            value: 'worst_5',
          },
          {
            text: this.$t('Best 5'),
            value: 'best_5',
          },
          {
            text: this.$t('Last 1'),
            value: 'last_1',
          },
          {
            text: this.$t('Last 3'),
            value: 'last_3',
          },
          {
            text: this.$t('Last 5'),
            value: 'last_5',
          },
          {
            text: this.$t('Last 10'),
            value: 'last_10',
          },
          {
            text: this.$t('Last 15'),
            value: 'last_15',
          },
          {
            text: this.$t('Last 20'),
            value: 'last_20',
          },
        ],
      }
    },
    loading() {
      return this.$store.state.spider.loadingData
    },
    benchmark() {
      return this.$store.state.spider.benchmark
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(newVal) {
        this.$store.commit('spider/filterData', newVal)
        this.$store.commit('spider/updateStatistics')
        this.getPageData(this.$store.state.spider.stats)
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch('spider/updatePlayers')
    this.$store.dispatch('spider/updateGroups')
  },
  created() {
    if (this.groupId || this.$store.state.spider.selectedGroup) {
      this.$store.commit('spider/setSelectedGroup', this.groupId || this.$store.state.spider.selectedGroup)
      if (this.$store.state.spider.groups.length > 0) {
        this.filters.selectedGroup = this.$store.state.spider.groups.find(item => item.id === (this.groupId || this.$store.state.spider.selectedGroup))
      } else {
        this.$store.dispatch('spider/updateGroups').then(() => {
          this.filters.selectedGroup = this.$store.state.spider.groups.find(item => item.id === (this.groupId || this.$store.state.spider.selectedGroup))
        })
      }
    }
    this.$store.dispatch('spider/updateStats', { filters: this.filters, trends: this.trends }).then(() => {
      this.getPageData(this.$store.state.spider.stats)
    })
  },
  methods: {
    getPageData() {
      this.loadingTab = true
      const summaryTables = []
      Object.values(configuration).forEach(value => {
        const tables = []
        value.forEach(ArrayItem => {
          const scoringStats = []
          this.$store.state.spider.stats.forEach(stat => {
            scoringStats.push({ ...stat.player, ...stat.stats[ArrayItem.key] })
          })
          ArrayItem.tables.forEach(t => {
            tables.push({
              title: t.title,
              data: scoringStats.map(item => {
                const r = {
                  id: item.id,
                  full_name: item.fullName,
                  value: item[t.key],
                }
                return r
              }).sort((a, b) => {
                if (t.benchmarktype === 'L') {
                  return a.value - b.value
                }
                return b.value - a.value
              }).map((item, rank) => ({ ...item, rank: rank + 1 })),
            })
          })
        })
        summaryTables.push({
          title: value[0].title,
          data: tables[0].data.map(item => {
            const r = {
              rankTotal: tables.map(sousTable => {
                const { rank } = sousTable.data.find(pl => pl.id === item.id)
                return rank
              }).reduce((aa, bb) => aa + bb, 0),
              id: item.id,
              full_name: item.full_name,
            }
            return r
          }).sort((a, b) => a.rankTotal - b.rankTotal).map((item, rank) => ({ ...item, rank: rank + 1 })),
        })
      })
      this.summaryTables = summaryTables
      this.finalSummary = summaryTables[0].data.map(item => {
        const r = {
          rankTotal: summaryTables.map(sousTable => {
            const { rank } = sousTable.data.find(pl => pl.id === item.id)
            return rank
          }).reduce((aa, bb) => aa + bb, 0),
          id: item.id,
          full_name: item.full_name,
        }
        return r
      }).sort((a, b) => a.rankTotal - b.rankTotal).map((item, rank) => ({ ...item, rank: rank + 1 }))
      const chartPlayersId = this.finalSummary.map(item => item.id).slice(0, 5)
      const chartData = this.summaryTables[0].data.filter(item => chartPlayersId.includes(item.id))
        .map(p => {
          const data = []
          this.summaryTables.forEach(t => {
            data.push(t.data.find(i => i.id === p.id).rank)
          })
          return {
            name: p.full_name,
            data,
          }
        })
      this.series = chartData
      this.labels = this.summaryTables.map(item => item.title)
      this.loadingTab = false
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
